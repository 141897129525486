@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
body {
  font-family: "Inter", sans-serif;
}
:root {
  --primary-color: #0000ff;
}
.text-primary {
  color: var(--primary-color);
}
.bg-primary {
  background: var(--primary-color);
}
.border-primary {
  border-color: var(--primary-color);
}
.bannerimg img {
  height: 700px;
  object-fit: cover;
  width: 100%;
}
.bannerimg:after {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #0000004f;
  z-index: 1;
  content: "";
}
.bannercontent {
  position: absolute;
  top: 20%;
  z-index: 9;
  left: 0;
}
.bannercontent h1 {
  font-size: 48px;
  line-height: 1.4;
}
.bannersearch {
  background: #00000094;
  padding: 20px;
  border-radius: 10px;
}
.fromfield {
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
}
.form-input {
  width: 150px;
  height: 36px;
  background: transparent;
  color: #fff;
  font-size: 14px;
}
.form-input option {
  background-color: #fff;
  color: #000;
}
.bannersearch .btn {
  min-width: 150px;
}
.form-input::placeholder {
  color: #fff;
}
.form-input::-ms-input-placeholder {
  color: #fff;
}
.specialistBox-body {
  padding: 12px;
  border-radius: 50px;
  border: 1px solid #f8f8f8;
  box-shadow: 0 3px 5px #0003;
  transition: all 0.3s;
}
.specialistBox-body:hover {
  box-shadow: 0 5px 15px #00000029;
  border-color: #d3e9ff;
}
.specialistIcon {
  width: 54px;
  height: 54px;
  line-height: 54px;
  background: #d3e9ff;
  border-radius: 50%;
  margin-right: 12px;
  padding: 8px 0;
}
.specialistIcon img {
  height: 35px;
  margin: auto;
}
.specialistBox-body h2 {
  font-size: 16px;
  font-weight: 600;
}
.specialistBox-body span {
  font-size: 12px;
  color: #373737;
}
.speBtn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  box-shadow: 0 2px 3px #0000004a;
}
.pagetitle:after {
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 56px;
  height: 5px;
  background: var(--primary-color);
  content: "";
} 
.drCard {
  border-radius: 20px;
  position: relative;
  box-shadow: 0 4px 5px #00000026;
  transition: all 0.3s;
}
.drCard:hover {
  box-shadow: 0 5px 20px #0000002b;
}
.drcardImg {
  display: block;
  margin-bottom: 10px;
}
.drcardImg img {
  width: 100%;
  aspect-ratio: 5/3;
  object-fit: cover;
  border-radius: 20px;
}
.drCard p {
  font-size: 14px;
  margin-bottom: 5px;
}
.drCard .ratingstar {
  font-size: 13px;
}
.ratingstar span {
  margin-right: 3px;
  color: #ccc;
}
.ratingstar span.active {
  color: #ffc001;
}
.wishlist {
  width: 30px;
  height: 30px;
  background: #0000004f;
  line-height: 31px;
 text-align: center;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  top: 22px;
  right: 22px;
}
.blogButton {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  margin-left: 10px;
  background: #0000004f;
}

.wishlist-active {
  width: 30px;
  height: 30px;
  background: #fb3838;
  line-height: 31px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  top: 22px;
  right: 22px;
}
.wishlist:hover {
  background: #fb3838;
  color: #fff;
}
.faqpnl .pagetitle:after {
  right: 0;
  margin: auto;
}
.faqaccordian {
  border-radius: 30px;
  box-shadow: 0 4px 5px #00000026;
}
.downloadappBox {
  border-radius: 20px;
  padding: 80px 80px;
}
.downloadappBox h2 {
  font-size: 42px;
  line-height: 1.3;
}
.downloadappBox {
  background-size: cover;
  background-position: right;
}
.appLogo img {
  height: 50px;
  object-fit: contain;
}
.mobileCover {
  margin-bottom: -182px;
}
.awardspnl .pagetitle:after {
  right: 0;
  margin: auto;
}
.awardSlide .slick-slide .awardLogo {
  border: 1px solid #dadada;
  border-radius: 10px;
  padding: 15px;
}
.awardLogo img {
  height: 65px;
  object-fit: contain;
  margin: auto;
}
.awardSlide .slick-slide {
  padding: 10px;
}
.footer-top {
  background: #f6f6f6;
}
.footerlogo img {
  height: 50px;
}
.footer-copyright {
  background: #ececed;
}
.formsignin {
  border-radius: 20px;
}
.innerbanner .bannerImg {
  width: 100%;
  height: 202px;
  object-fit: cover;
}
.dashboardNav {
  border-radius: 20px;
  overflow: hidden;
}
.cameraCover {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  background: #00000082;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.profileImg {
  width: 140px;
  height: 140px;
  margin: -70px auto 0;
}
.profileImg img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #dddddd;
}
.profileImg .cameraCover {
  top: 55%;
  right: -8px;
}
.middleBorder:after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -25px;
  content: "";
  background: #0000ff;
  width: 55px;
  height: 5px;
}
.expServiceBlock {
  background-image: url();
}
.slidermember.awardSlide .slick-slide .awardLogo {
  border: 0;
}
@media (max-width: 991px) {
  .downloadappBox h2 {
    font-size: 30px;
  }
  .downloadappBox {
    padding: 80px 30px;
  }
  .mobileCover {
    margin: 0;
  }
  .bannercontent h1 {
    font-size: 30px;
  }
  .form-input {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .downloadappBox {
    padding: 30px;
  }
  .downloadappBox h2 {
    font-size: 25px;
  }
  .bannercontent h1 {
    font-size: 25px;
  }
  .bannercontent {
    top: 10%;
  }
  .bannersearch .fromfield {
    margin-bottom: 5px;
  }
  .bannersearch .btn {
    min-width: 150px;
    width: 100%;
    min-height: 50px;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #0376e2;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #0376e2;
}
.react-datepicker__input-container {
  height: 100%;
}
.react-datepicker__input-container input {
  height: 100%;
}
.react-datepicker {
  position: absolute !important;
  left: 20%;
  top: 80%;
}
.TabContainer {
  display: flex;
  align-self: "center";
  flex-direction: "row";
  height: 50px;
  width: max-content;
  border-width: 1px;
  border-color: #ddd;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 100px;
  align-items: "center";
  justify-content: "center";
}

.TabBlock {
  display: flex;
  width: max-content;
  padding: 10px;
  align-items: "center";
  justify-content: "center";
  border-radius: 100px;
  height: 46px;
  cursor: pointer;
}
.TabText {
  font-size: 14px;
  font-weight: "600";
}
.ActiveTabBlock {
  background-color: #0437eb;
}
.ActiveTabText {
  font-size: 14px;
  color: #fff;
}
.DirectionBtn {
  background-color: #0437eb;
  border-radius: 100px;
  padding-right: 15px;
  padding-left: 15px;
  height: 30px;
  right: 10px; /* Keep this value for initial right alignment */
  margin-left: 20px; /* Add margin to push the button to the right */
  color: #fff;
  font-size: 13px;
  font-weight: 600; /* Remove quotes around font-weight */
}



.pac-container {
  top: 615px !important;
  left: 95px !important;
}

.pac-container,
.pac-item {
  width: 370px !important;
}
.pac-logo::after {
  background-image: none !important;
}

.ql-toolbar.ql-snow {
  border-top-left-radius:15px;
  background: #eaecec;
  border-top-right-radius:15px;
}
.ql-container.ql-snow {
  border-bottom-left-radius:15px;
  border-bottom-right-radius:15px;
  height:300px
}
