.react-calendar__tile--active {
    background: #0000ff !important; 
    color: white;
}
.available-slot {
    background: #228B22 !important;
    color: white !important;
}
.disable-slot {
    background: rgb(182, 182, 182) !important;
    color: white !important;
}
.react-calendar__tile--disabled {
    background-color: #D5D4DF !important; 
    color: #000 !important;
    cursor: not-allowed !important;
}
.react-calendar{
    width: 100%;
    border: 0;
}
.react-calendar__tile{
    padding: 15px 10px;
}
abbr:where([title]){
    text-decoration: none;
}
.react-calendar button{
    border: .2px solid #fff;
}
.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from{
    font-weight: 600;
}
.slotList input[type="radio"]:checked + span {
    background-color: #dcdcff;
    border-color: blue;
    color: blue;
}
.disabledSlotList input[type="radio"]:checked + span {
    background-color: #ffdcdc;
    border-color: #ff0000;
    color: red;
}