.dropdown-container {
    position: relative;
    width: 100%;
  }
  
  .dropdown-header {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-top: none;
    background-color: white;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    width: 95%;
    margin: auto;
    padding: 5px;
  }
  
  .dropdown-list-item {
    padding: 4px 10px;
    font-size: 14px;
  }
  
  .dropdown-list-item label {
    display: flex;
    align-items: center;
  }
  
  .dropdown-list-item input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .dropdown-arrow {
    margin-left: 10px;
  }
  